<template>
  <base-loader v-if="loading"></base-loader>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
    <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="d-flex align-items-center justify-content-between">
        <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
          {{ title }}
        </h3>
        <div>
          <button class="btn btn-success btn-sm" @click="openModal()">
            <i class="fas fa-plus"></i>
          </button>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <base-table
        class="table align-items-center table-flush"
        :class="type === 'dark' ? 'table-dark' : ''"
        :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
        tbody-classes="list"
        :data="barcodes"
      >
        <!-- Table Head -->
        <template v-slot:columns>
          <th>#</th>
          <th>{{ $t("tables.from") }}</th>
          <th>{{ $t("tables.to") }}</th>
          <th>{{ $t("tables.current") }}</th>
          <th>{{ $t("tables.from") }}</th>
          <th>{{ $t("tables.to") }}</th>
          <th>{{ $t("tables.type") }}</th>
          <th>{{ $t("tables.type_value") }}</th>
          <th>{{ $t("tables.is_selected") }}</th>
          <th>{{ $t("tables.status") }}</th>
          <th>{{ $t("tables.date") }}</th>
          <!-- <th>{{ $t("tables.action") }}</th> -->
        </template>

        <!-- Table Body -->
        <template v-slot:default="row">
          <td class="budget">
            {{ row.item.id }}
          </td>
          <td class="budget">
            {{ row.item.serial_from }}
          </td>
          <td class="budget">
            {{ row.item.serial_to }}
          </td>
          <td class="budget">
            {{ row.item.serial_current }}
          </td>
          <td class="budget">
            {{ row.item.serial_date_from }}
          </td>
          <td class="budget">
            {{ row.item.serial_date_to }}
          </td>
          <td class="budget">
            {{ row.item.type }}
          </td>
          <td class="budget">
            {{ row.item.trader_name || row.item.company_name }}
          </td>
          <td class="budget">
            {{ row.item.is_selected }}
          </td>
          <td class="budget">
            {{
              row.item.status == 1
                ? $t("tables.active")
                : $t("tables.in-active")
            }}
          </td>
          <td class="budget">
            {{
              moment(row.item.created_at).format(
                "ddd [-] DD[-]MM[-]YYYY [/] HH:mm a"
              )
            }}
          </td>
          <!-- <td class="budget text-right">
            <button class="btn btn-success btn-sm" @click="openModal(row.item)">
              <i class="fas fa-edit"></i>
            </button>
          </td> -->
        </template>
      </base-table>
    </div>
  </div>
  <!-- Modal -->
  <div
    v-if="formModal"
    class="modal fade show d-block"
    id="formModal"
    tabindex="-1"
    aria-labelledby="formModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="formModalLabel">
            {{ $t("form.barcode_form") }}
          </h5>
          <button
            type="button"
            class="btn-secondary"
            @click="formModal = false"
          >
            x
          </button>
        </div>
        <div class="modal-body">
          <form>
            <!-- Errors Handling -->
            <div class="row bg-danger py-3 mb-3" v-if="validationErrors.length">
              <div
                class="col-md-6"
                v-for="(error, index) in validationErrors"
                :key="index"
              >
                <span class="text-white mr-2 error-handling">{{ error }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 mb-3">
                <label class="form-control-label">{{ $t("form.from") }}</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="updatedData.serial_from"
                />
              </div>
              <div class="col-lg-6 mb-3">
                <label class="form-control-label">{{ $t("form.to") }}</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="updatedData.serial_to"
                />
              </div>
              <div class="col-lg-12 mb-3">
                <label class="form-control-label">{{ $t("form.type") }}</label>
                <select
                  class="form-control form-control-alternative"
                  v-model="updatedData.type"
                >
                  <option value="" selected>
                    {{ $t("form.choose_type") }}
                  </option>
                  <option value="company">
                    {{ $t("form.company") }}
                  </option>
                  <option value="trader">
                    {{ $t("form.trader") }}
                  </option>
                </select>
              </div>
              <div class="col-lg-12 mb-3" v-if="updatedData.type == 'company'">
                <label class="form-control-label">{{
                  $t("form.company")
                }}</label>
                <select
                  class="form-control form-control-alternative"
                  v-model="updatedData.company_id"
                >
                  <option value="0" selected>
                    {{ $t("form.choose_company") }}
                  </option>
                  <option
                    v-for="(item, index) in companies"
                    :value="item.id"
                    :key="index"
                  >
                    {{ item.name }}
                  </option>
                </select>
              </div>
              <div class="col-lg-12 mb-3" v-if="updatedData.type == 'trader'">
                <label class="form-control-label">{{
                  $t("form.trader")
                }}</label>
                <select
                  class="form-control form-control-alternative"
                  v-model="updatedData.trader_id"
                >
                  <option value="0" selected>
                    {{ $t("form.choose_trader") }}
                  </option>
                  <option
                    v-for="(item, index) in traders"
                    :value="item.id"
                    :key="index"
                  >
                    {{ item.name }}
                  </option>
                </select>
              </div>
              <div class="col-lg-6 d-flex align-items-center">
                <div class="form-group has-label mb-0">
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="status"
                      true-value="1"
                      false-value="0"
                      v-model="updatedData.status"
                    />
                    <label class="form-check-label" for="status">
                      {{ $t("form.status") }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            @click="formModal = false"
          >
            {{ $t("form.close") }}
          </button>
          <button
            type="button"
            class="btn btn-success"
            @click="updateBarcode()"
          >
            {{ $t("form.save") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";

export default {
  name: "barcodes-table",
  props: {
    type: {
      type: String,
    },
    title: String,
  },
  data() {
    return {
      renderComponent: false,
      loading: false,
      validationErrors: [],
      barcodes: "",
      companies: "",
      traders: "",
      formModal: false,
      currentData: {},
      updatedData: {},
    };
  },
  created() {
    this.moment = moment;
    this.getBarcodes("admin/administration/barCodes/index");
    this.getCompanies();
    this.getTraders();
  },
  methods: {
    getBarcodes: function ($pagination) {
      this.loading = true;
      axios
        .get($pagination)
        .then((response) => {
          if (response.data.error) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
            });
          } else {
            this.barcodes = response.data.data;
          }
        })
        .catch(() => {
          if (!window.navigator.onLine) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
              text: `${this.$t("validation.check network")}`,
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    // All Companies
    getCompanies: function () {
      this.loading = true;
      axios
        .get("admin/administration/companies/index")
        .then((response) => {
          if (response.data.error) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
            });
          } else {
            this.companies = response.data.data;
          }
        })
        .catch(() => {
          if (!window.navigator.onLine) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
              text: `${this.$t("validation.check network")}`,
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    // All Traders
    getTraders: function () {
      this.loading = true;
      axios
        .get("admin/administration/traders/index")
        .then((response) => {
          if (response.data.error) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
            });
          } else {
            this.traders = response.data.data;
          }
        })
        .catch(() => {
          if (!window.navigator.onLine) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
              text: `${this.$t("validation.check network")}`,
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    // Open Modal
    openModal(barcodeData = null) {
      this.updatedData = {};
      this.formModal = true;
      this.validationErrors = [];
      if (barcodeData) {
        // this.updatedData = { ...barcodeData };
        // this.updatedData.banner_id = this.updatedData.id;
      }
    },
    // Create Or Update Barcode
    updateBarcode: function () {
      this.loading = true;
      let formData = this.toFormData(this.updatedData);
      var url;
      if (this.updatedData.barcode_id) {
        url = "admin/administration/barCodes/update";
      } else {
        url = "admin/administration/barCodes/store";
      }
      axios
        .post(url, formData)
        .then((response) => {
          if (response.data.error) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
            });
          } else {
            if (response.data.status == 0) {
              this.validationErrors.push(response.data.message);
              this.$swal({
                position: "center",
                icon: "error",
                title: response.data.message,
                timer: 1200,
                showConfirmButton: false,
              });
            } else {
              if (this.updatedData.id) {
                this.barcodes.filter((item) => {
                  if (this.updatedData.id === item.id) {
                    item = this.updatedData;
                  }
                });
              } else {
                this.barcodes.push(this.updatedData);
              }
              this.updatedData = {};
              this.formModal = false;
              this.$swal({
                position: "center",
                icon: "success",
                title: `${this.$t("validation.success")}`,
                text: response.data.message,
                showConfirmButton: false,
                timer: 1200,
              });
            }
          }
        })
        .catch(() => {
          if (!window.navigator.onLine) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
              text: `${this.$t("validation.check network")}`,
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    uploadImage(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.updatedData.image = files[0];
    },
    // Form Fields
    toFormData: function (obj) {
      var fd = new FormData();
      if (this.updatedData["barcode_id"]) {
        fd.append("_method", "put");
        // fd.append("banner_id", this.updatedData["id"]);
      }
      for (var i in obj) {
        if (obj[i]) {
          fd.append(i, obj[i]);
        }
      }
      return fd;
    },
  },
};
</script>
<style scoped>
.wrap {
  white-space: normal;
}
</style>
