<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col">
          <barcodes-table
            type="dark"
            :title="$t('sidebar.barcode')"
          ></barcodes-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BarcodesTable from "../Tables/Barcodes/Barcodes.vue";
export default {
  name: "Barcodes",
  components: {
    BarcodesTable,
  },
};
</script>
<style></style>
